@use '@angular/material' as mat;

@mixin iot-platform-ui-breadcrumb-item-theme($theme) {
  $primary: mat.get-color-from-palette(map-get($theme, primary), default);
  $primary-color: mat.get-color-from-palette(map-get($theme, primary), default-contrast);
  $accent: mat.get-color-from-palette(map-get($theme, accent), default);
  $accent-color: mat.get-color-from-palette(map-get($theme, accent), default-contrast);

  .breadcrumb {
    &__back-button {
      background: #a5d564;
      color: #ffffff;
    }
     &__item {
       background-color: $accent;
       color: $accent-color;
       .mat-icon {
         color: $accent-color;
       }
       .mat-progress-spinner circle,
       .mat-spinner circle {
         stroke: $accent-color;
       }
       &--passive {
         background-color: transparent;
         color: $accent;
         .mat-icon {
           color: $accent;
         }
         .mat-progress-spinner circle,
         .mat-spinner circle {
           stroke: $accent;
         }
       }
     }
  }
}
