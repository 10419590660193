@mixin iot-platform-ui-download-card-theme($theme) {
  $primary: mat-color(map-get($theme, primary), default);
  $accent: mat-color(map-get($theme, accent), default);
  $grey: mat-color(mat-palette($mat-grey));

  .download-card {
    &_box {
      .mat-icon {
        color: $grey;
      }
      .mat-button {
        background-color: $grey;
      }
    }
    .download-card_box {
      &:hover {
        border-color: $accent;
        border: 3px solid $accent;
        .mat-icon {
          color: $accent;
        }
        .mat-button {
          background-color: $accent;
        }
      }
    }
  }
}
