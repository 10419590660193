@use '@angular/material' as mat;
@mixin asset-device-association-component-theme($theme) {
  $primary: mat.get-color-from-palette(map-get($theme, primary), default);
  $primary-color: mat.get-color-from-palette(map-get($theme, primary), default-contrast);
  $accent: mat.get-color-from-palette(map-get($theme, accent), default);
  $accent-color: mat.get-color-from-palette(map-get($theme, accent), default-contrast);

  .association-toolbars-title {
    color: #b0b0b0;
  }

  .association-toolbars {
    color: #b0b0b0;
  }

  .association-section {
    border-bottom: 1px solid #b0b0b0;
  }

  .association-object-card {
    color: $primary-color;
  }

  .no-association-message {
    color: #b0b0b0;
  }

  .mat-mini-fab.display-mode-selected.light-button {
    background-color: $accent;
    color: $accent-color;

    &:hover {
      cursor: default;
      background-color: $accent;
      color: $accent-color;
    }
  }

  .association-object-card.selected {
    background-color: $accent;
    color: $accent-color;
  }

  .display-by-active {
    .association-toolbars-title {
      color: $accent;
      font-weight: bold;
    }

    & + hr {
      background-color: $accent;
    }
  }
}
