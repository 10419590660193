@use '@angular/material' as mat;
@mixin material-checkbox-theme($theme) {
  $primary-color: mat.get-color-from-palette(map-get($theme, primary), default-contrast);

  mat-checkbox {
    .mat-checkbox-label {
      color: $primary-color;
      font-size: 12px;
    }

    .mat-checkbox-inner-container {
      height: 14px;
      width: 14px;

      .mat-ripple.mat-checkbox-ripple {
        left: calc(50% - 15px);
        top: calc(50% - 15px);
        height: 30px;
        width: 30px;
        border-radius: 50%;
      }
    }
  }
}
