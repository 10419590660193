@use '@angular/material' as mat;
@import '../../../src/lib/components/tag-editor/tag-editor-form/tag-editor-form.component.scss-theme';
@import '../../../src/lib/components/roles-crud/roles-crud.component.scss-theme';
@import '../../../src/lib/components/dialogs/dialog-business-profile-add-form/dialog-business-profile-add-form.component.scss-theme';
@import '../../../src/lib/components/dialogs/dialog-users-add-form/dialog-users-add-form.component.scss-theme';
@import '../../../src/lib/features/admin-users/components/user-detail/user-detail.component.scss-theme';
@import '../../../../i18n/src/lib/features/translation-management/styles/ag-grid-custom-theme.scss-theme';

@mixin backoffice-ui-theme($theme) {
  $primary-color: mat.get-color-from-palette(map-get($theme, primary), default-contrast);
  $accent: mat.get-color-from-palette(map-get($theme, accent), default);
  $accent-color: mat.get-color-from-palette(map-get($theme, accent), default-contrast);

  @include tag-editor-form-theme($theme);
  @include role-crud-theme($theme);
  @include dialog-business-profile-add-form-theme($theme);
  @include dialog-users-add-form-theme($theme);
  @include user-detail-theme($theme);
  @include ag-grid-custom-theme($theme);

  .mv-toolbar-row {
    font-size: 16px;
    line-height: 30px;
    color: mat.get-color-from-palette(map-get($theme, primary), default-contrast);
    background-color: mat.get-color-from-palette(map-get($theme, primary), default);
    width: 100%;
  }

  .mv-toolbar-col {
    display: flex;
    align-items: center;
    padding: 10px 15px;
    background-color: mat.get-color-from-palette(map-get($theme, primary), default);

    .app-round-button-mv {
      color: mat.get-color-from-palette(map-get($theme, accent), default-contrast);
    }
  }

  .mv-toolbar-label {
    font-size: 20px;
    line-height: 40px;
    font-weight: 600;
  }

  .bp-page {
    max-height: 85%;
    height: 100%;
    overflow: no-display;
  }

  .no-profile {
    padding-top: 20px;
    font-size: 18px;
    color: #797979;
    font-weight: 600;
    text-align: center;
  }

  .icon-disabled-white.mat-icon-button[disabled] {
    color: $accent-color;
    opacity: 0.4;
  }
}
