@use '@angular/material' as mat;

@mixin escalation-protocol-info-theme($theme) {
  $accent: mat.get-color-from-palette(map-get($theme, accent), default);
  $primary-color: mat.get-color-from-palette(map-get($theme, primary), default-contrast);

  .ep-info-container {
    color: $primary-color;
  }

  .ep-info-title,
  .ep-info-no-level-message {
    color: #b0b0b0;
  }

  .ep-lock-icon {
    color: $accent;
  }

  .ep-info-level-movable {
    color: $primary-color;
  }
}
