@use '@angular/material' as mat;
@import '../../../../scss/variables';

@mixin asset-variable-configure-form-theme($theme) {
  $primary: mat.get-color-from-palette(map-get($theme, primary), default);
  $warn: mat.get-color-from-palette(map-get($theme, warn), default);

  .asset-variable-summary {
    background-color: $light-grey;
  }

  .asset-variable-formula,
  .asset-variable-device-variable {
    em,
    .toggle-device-variable-value {
      color: mat.get-color-from-palette(map-get($theme, accent), default);
    }
  }

  .asset-variable-configure-formula-types {
    background-color: $primary;
  }

  .asset-variable-configure-formula-parameters-container,
  .asset-variable-configure-formula-evaluation-container,
  .asset-variable-configure-threshold-container,
  .threshold-form-container {
    background-color: $primary;

    &.step-validated {
      background-color: $success-green;
    }

    &.untouched-form,
    .step-not-filled {
      background-color: $primary;
    }

    &.step-not-validated {
      background-color: #f5d9dc;
    }
  }

  .threshold-form {
    .mat-form-field-infix {
      min-height: 1.2rem;
    }
    .calculate-dynamic-threshold-btn {
      min-width: 4.5rem;
      max-height: 2rem;
      padding: 0 0.5rem;
      line-height: 2rem;
      text-transform: capitalize;
    }
    .mat-button-toggle-group {
      .mat-button-toggle-checked {
        background-color: rgb(58 75 90) !important;
        color: #fff !important;

        .mat-button-toggle-button {
          color: rgb(233 239 246) !important;
        }
      }
      .mat-button-toggle-appearance-standard {
        max-height: 2rem;
        display: flex;
        align-items: center;
        text-transform: capitalize;
      }
      .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
        border-radius: 0 5px 5px 0;
      }
      .mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
         padding: 0 8px;
      }

      .mat-button-toggle-disabled {
        color: rgba(0, 0, 0, 0.26) !important;
        background-color: #eeeeee !important;
        &.mat-button-toggle-checked {
          background-color: #bdbdbd !important;
          color: #fff !important;
        }
      }
    }
  }
}
