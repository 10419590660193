@use '@angular/material' as mat;
@mixin material-chip-theme($theme) {
  $accent-color: mat.get-color-from-palette(map-get($theme, accent), default-contrast);

  .mat-chip.mat-standard-chip {
    color: $accent-color;

    min-height: 20px;
    font-size: 10px;
    font-weight: normal;
    margin: 1px;

    &:hover::after {
      opacity: 0;
    }

    &:active {
      box-shadow: none;
    }

    &:focus::after {
      opacity: 0;
    }

    mat-icon {
      width: 14px;
      height: 14px;
      font-size: 14px;
    }
  }

  .mat-chip-list-wrapper {
    margin: 0;
  }
}
