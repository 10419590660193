@use '@angular/material' as mat;
@mixin material-button-theme($theme) {
  $primary: mat.get-color-from-palette(map-get($theme, primary), default);
  $primary-color: mat.get-color-from-palette(map-get($theme, primary), default-contrast);
  $accent: mat.get-color-from-palette(map-get($theme, accent), default);
  $accent-color: mat.get-color-from-palette(map-get($theme, accent), default-contrast);
  $warn: mat.get-color-from-palette(map-get($theme, warn), default);

  button.button-regular {
    min-width: 150px;
    height: 40px;
    font-size: 12px;
    border-radius: 2px;
    text-transform: uppercase;
    color: $primary-color;
  }

  button.button-regular--action {
  }

  button.regular-round-button {
    &:hover {
      background-color: rgba(57, 76, 90, 0.05);
    }
  }

  button.reverse-primary-button,
  button.mat-mini-fab.reverse-primary-button {
    color: $primary;
    background-color: transparent;
  }

  button.reverse-accent-button,
  button.mat-mini-fab.reverse-accent-button {
    color: $accent;
    background-color: transparent;
  }

  button.reverse-warn-button {
    color: $warn;
    background-color: transparent;
  }

  button.mat-mini-fab.light-button {
    box-shadow: none;
    color: #b0b0b0;
    background-color: transparent;

    &:hover,
    &[aria-expanded='true'] {
      background-color: rgba(57, 76, 90, 0.05);
    }
  }

  button.mat-mini-fab.transparent-button {
    box-shadow: none;
    color: $primary-color;
    background-color: transparent;

    &:hover {
      background-color: rgba(57, 76, 90, 0.05);
    }

    &[disabled='true'] {
      color: #b0b0b0;
      background-color: transparent;
    }
  }
}
