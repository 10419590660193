@use '@angular/material' as mat;
@mixin material-accordion-theme($theme) {
  $primary-color: mat.get-color-from-palette(map-get($theme, primary), default-contrast);

  .mat-expansion-panel:not([class*='mat-elevation-z']) {
    border-radius: 0;
    box-shadow: none;
  }

  .mat-accordion {
    .mat-expansion-panel {
      box-shadow: none;
      color: $primary-color;
    }
  }

  .mat-expansion-panel-header {
    padding: 0 20px;
    font-size: 14px;
  }

  .mat-expansion-panel-header-title {
    color: $primary-color;
  }
}
