@use '@angular/material' as mat;

@mixin cards-container-theme($theme) {

  $accent: mat.get-color-from-palette(map-get($theme, accent), default);

  $card-xs-size: calc(24.99% - 15px);
  $card-sm-size: calc(33.33% - 15px);
  $card-md-size: calc(49.99% - 15px);
  $card-lg-size: 100%;

  .card {
    &__container {
      width: 100%;
      min-width: 100%;
      max-height: 29rem;
      overflow-y: auto;

      .card-xs,
      .card-sm,
      .card-md,
      .card-lg {
        flex-shrink: 1;
        box-sizing: border-box;
      }

      .card-xs {
        flex-basis: $card-xs-size;
      }

      .card-sm {
        flex-basis: $card-sm-size;
      }

      .card-md {
        flex-basis: $card-md-size;
      }

      .card-lg {
        flex-basis: $card-lg-size;
      }

      @media (min-width: 959px) {
        &--horizontal-layout {
          flex-wrap: nowrap !important;
          overflow-x: auto;
          padding-bottom: 10px;

          & > {
            .card-xs {
              min-width: $card-xs-size;
            }

            .card-sm {
              min-width: $card-sm-size;
            }

            .card-md {
              min-width: $card-md-size;
            }

            .card-lg {
              min-width: $card-lg-size;
            }
          }
        }
      }

      .cdk-virtual-scroll-viewport {
        width: 100%;
        height: 8.5rem;

        .cdk-virtual-scroll-content-wrapper {
          flex-flow: row wrap;
          box-sizing: border-box;
          display: flex;
          place-content: stretch flex-start;
          align-items: stretch;
        }

        &.cdk-virtual-scroll-orientation-horizontal {
          .cdk-virtual-scroll-content-wrapper {
            flex-wrap: nowrap !important;
          }

          .card-xs,
          .card-sm,
          .card-md,
          .card-lg, {
            flex-basis: unset !important;
          }

          .card-xs {
            width: 14.4rem;
          }

          .card-sm {
            width: 19.5rem;
          }

          .card-md {
            width: 29.7rem;
          }

          .card-lg {
            width: 60.4rem;
          }
        }

        &.cdk-virtual-scroll-orientation-vertical {
          height: 29rem;
        }
      }

      .simple-card--with-icon {
        .card__title {
          text-transform: uppercase;
          font-size: 20px;
          font-weight: 600;
          line-height: 27px;
        }
      }

      .bg-primary {
        .simple-card__image-container {
          background-color: $accent;

          .simple-card__image,
          .simple-card__icon {
            color: #FFFFFF;
          }
        }
      }

      .threshold-card__form .mat-form-field-infix {
        width: auto;
      }

      .card__contact .site-contact {
        width: 100%;
        max-width: 100%;
        height: auto;
        box-shadow: none;
        padding: 0;
        margin: 0;
      }
    }

    &__title {
      font-weight: 600;
      font-size: 14px;
      line-height: 19px;
      color: #394C5A;
    }

    &__description {
      margin: 8px 0;
      font-size: 11px;
      line-height: 18px;

      label {
        color: #B0B0B0;
      }
    }
  }

  .default-card {
    height: 100% !important;
    min-height: 100px;
  }
  .mat-menu-panel {
    .no-data-message {
      width: auto;
    }
  }

  .card__container,
  .cdk-virtual-scroll-content-wrapper {
    padding-left: 1rem;

    & > * {
      margin: 0 15px 15px 0;
    }
  }

  .card__container {
    &--virtual-scroll {
      overflow-x: hidden !important;
      max-height: unset !important;
      padding-left: 0rem !important;

      & > * {
        margin: 0 !important;
      }

      &.card__container--horizontal-layout {
        @media (min-width: 959px) {
          flex-wrap: unset !important;
          overflow-x: hidden !important;
          padding-bottom: 0 !important;
        }
      }
    }
  }
}
