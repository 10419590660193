/**
  Login page theme
 */
@use '@angular/material' as mat;
@mixin login-theme($theme) {
  $accent: mat.get-color-from-palette(map-get($theme, accent), default);
  $sand-primary:  mat.get-color-from-palette(mat.define-palette($sand-palette, 500, 300, 900));
  $sand-secondary:  mat.get-color-from-palette(mat.define-palette($sand-palette, 50, 300, 900));

  .login-page {
    &__info {
      background-color: $sand-secondary;
      border-color: $sand-primary !important;
      opacity: 0.6;
      &-welcome {
        h2 {
          color: $sand-primary !important;
        }
      }
    }

    &__form {
      .mat-card-title {
        color: $accent !important;
      }
      .layout-login-button--submit,
      button.progress-bar-button {
        border-color: $accent !important;
      }
    }

    /**
     Theme override
     Override nested component style here as global css to prevent the use of ng-deep
     It's better than use the encapsulation.None
     */

    .mat-progress-bar.mat-accent {
      .mat-progress-bar-background,
      .mat-progress-bar-buffer,
      .mat-progress-bar-fill::after {
        background-color: $sand-primary !important;
      }
      .mat-progress-bar-background,
      .mat-progress-bar-buffer {
        opacity: 0.3;
      }
    }

    .mat-form-field .mat-icon {
      color: #D9D9D9;
    }
    .login-reset-pwd-button button {
      color: #ADADAD;
      text-decoration: underline;
    }

    .mat-form-field-prefix .mat-icon {
      display: none !important;
    }

    .login-select-profile-entities {
      max-height: 184px !important;
    }

    .layout-login-form-actions {
      position: relative !important;
      display: flex;
      justify-content: flex-end;
      right: 0 !important;
      bottom: 0 !important;
    }

    .layout-login-regular-title {
      display: none;
    }

    .login-select-profile-loader {
      left: 12.3%;
    }

    &__form {
      &.step-select-bp.loading-bps {
        height: 21.1rem;
      }
      &.step-login {
        &.has-error {
          border-bottom-left-radius: 34px;
          top: 2.7rem;
          min-height: 26rem;
          left: -3rem;

          .mat-card {
            padding-left: 14%;
          }

          .layout-login-error {
            margin: 1rem 0;
          }

          .layout-login-form-actions {
            margin-top: 0.6rem;
          }

          .login-reset-pwd-button {
            margin-bottom: 0 !important;
          }
        }
      }

      &.step-password {
        left: -13%;
        width: 90%;
        top: 5.7rem;
        border-bottom-left-radius: 34px;
        min-height: 32rem;

        .mat-card {
          padding-left: 20%;
        }
      }
    }
  }
}
