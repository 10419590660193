@mixin rotateLeft() {
  -webkit-animation: spin-left 2s linear infinite;
  -moz-animation: spin-left 2s linear infinite;
  animation: spin-left 2s linear infinite;
}
@-moz-keyframes spin-left {
  100% {
    -moz-transform: rotate(-360deg);
  }
}
@-webkit-keyframes spin-left {
  100% {
    -webkit-transform: rotate(-360deg);
  }
}
@keyframes spin-left {
  100% {
    -webkit-transform: rotate(-360deg);
    transform: rotate(-360deg);
  }
}
