@use '@angular/material' as mat;

@mixin iot-platform-ui-tag-list-by-concept($theme) {
  $primary-color: mat.get-color-from-palette(map-get($theme, primary), default-contrast);
  $accent-color: mat.get-color-from-palette(map-get($theme, accent), default-contrast);

  .concept-name {
    color: $primary-color;
  }

  .tag-category-name {
    color: $primary-color;
  }
}
