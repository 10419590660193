@mixin default-card-theme($theme) {
  .default-card {
    &--with-custom-btn {
      .default-card__custom-button,
      .default-card__custom-button > * {
        position: absolute;
        width: 100%;
        height: 100%;
        button {
          width: inherit;
          height: inherit;
          .mat-button-wrapper {
            margin-left: -6rem;
            position: absolute;
            top: 1.8rem;
          }
        }
      }
      .default-card-title {
        padding-left: 1.5rem;
      }
    }
  }
}
