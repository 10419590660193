@use '@angular/material' as mat;
@import '../variables';

@mixin material-menu-theme($theme) {
  $primary-color: mat.get-color-from-palette(map-get($theme, primary), default-contrast);
  $primary-darker: mat.get-color-from-palette(map-get($theme, accent), darker);

  .mat-menu-panel {
    min-height: 48px;

    &.height-300 {
      max-height: 300px;
      overflow: auto;
    }
  }

  .sidenav-menu-panel {
    background-color: $primary-darker;
    width: 250px;
    max-height: 100%;
    position: absolute;
    top: -36px;
    left: calc(#{$app-sidenav-width} - 4px);
    border-radius: 0;
    min-height: 40px;
    overflow-x: hidden;

    .mat-menu-content:not(:empty) {
      padding: 0;
    }
  }

  .sidenav-backdrop-class {
    left: $app-sidenav-width;
  }

  .mat-menu-content,
  .mat-menu-trigger {
    text-transform: uppercase;
  }

  .mat-menu-item {
    color: $primary-color;
    font-size: 12px;
    height: 32px;
    line-height: 16px;

    .mat-icon {
      color: inherit;
    }
  }
}
