@use '@angular/material' as mat;
@mixin role-crud-theme($theme) {
  $primary-color: mat.get-color-from-palette(map-get($theme, primary), default-contrast);
  $accent: mat.get-color-from-palette(map-get($theme, accent), default);
  $accent-color: mat.get-color-from-palette(map-get($theme, accent), default-contrast);

  .roles-crud-section-title {
    color: $primary-color;
    opacity: 0.5;
  }

  .roles-crud-section-concept-name {
    color: $primary-color;
  }

  .crud-icons {
    color: $primary-color;
  }

  .manage-concept-button {
    color: $accent;

    &:hover {
      background-color: $accent;
      color: $accent-color;
    }
  }
}
