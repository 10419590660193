@use '@angular/material' as mat;
@mixin material-list-theme($theme) {
  $primary-color: mat.get-color-from-palette(map-get($theme, primary), default-contrast);
  $accent: mat.get-color-from-palette(map-get($theme, accent), default);
  $accent-color: mat.get-color-from-palette(map-get($theme, accent), default-contrast);

  .mat-list-base {
    padding-top: 0;
  }

  mat-list.mat-list-base .mat-list-item {
    color: $primary-color;
    height: 32px;

    &.selected {
      background-color: $accent;
      color: $accent-color;
    }

    &.hoverable:hover {
      background-color: $accent;
      color: $accent-color;
    }
  }

  mat-nav-list.mat-list-base .mat-list-item {
    color: $primary-color;
    height: 32px;

    &.fs-12 {
      font-size: 12px;
    }

    &.selected {
      background-color: $accent;
      color: $accent-color;
    }

    &.hoverable:hover {
      background-color: $accent;
      color: $accent-color;
    }
  }

  mat-list.height-300 {
    min-height: 300px;
    max-height: 300px;
    overflow: auto;
  }

  mat-list.two-line-list.mat-list-base .mat-list-item.mat-2-line,
  mat-nav-list.two-line-list.mat-list-base .mat-list-item.mat-list-item-with-avatar {
    height: 50px;
  }

  mat-list.one-line-list.mat-list-base .mat-list-item.mat-list-item-with-avatar,
  mat-nav-list.one-line-list.mat-list-base .mat-list-item.mat-list-item-with-avatar {
    height: 40px;
  }
}
