@use '@angular/material' as mat;
@mixin tag-editor-form-theme($theme) {
  $primary: mat.get-color-from-palette(map-get($theme, primary), default);
  $primary-color: mat.get-color-from-palette(map-get($theme, primary), default-contrast);
  $warn: mat.get-color-from-palette(map-get($theme, warn), default);

  .tag-editor-duplicate-warning {
    color: $warn;
  }
}
