@use '@angular/material' as mat;

@mixin calendar-edit-popup-theme($theme) {
  $primary: mat.get-color-from-palette(map-get($theme, primary), default);
  $warn: mat.get-color-from-palette(map-get($theme, warn), default);

  .member-list-item {
    background-color: $primary;
  }
}
