@import 'variables';

.full-width {
  width: 100%;
}

.full-height,
.full-height-with-overflow,
full-height-with-overflow-y {
  height: 100%;
}

.full-height-with-overflow,
.full-height-with-overflow-y {
  max-height: 100%;
  overflow: auto;
}
.full-height-with-overflow-y {
  overflow-x: hidden !important;
  overflow-y: auto !important;
}

.full-height-width {
  height: 100%;
  width: 100%;
}

.width-65 {
  width: 65%;
}

.width-20 {
  width: 20%;
}

.p-10 {
  padding: 10px;
}

.p-20 {
  padding: 20px;
}

.p-0 {
  padding: 0;
}

.layout-visible {
  visibility: visible;
}

.layout-invisible {
  visibility: hidden;
}

.layout-display-none {
  display: none;
}

.layout-display-block-fadein {
  display: block;
  animation: fadein 1s cubic-bezier(0.4, 0.2, 0.59, 0.92);
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.regular-background {
  background-color: $light-grey;
}

.regular-1px-line {
  height: 1px;
  border: 0;
  background-color: #c8c8c8;
}

.fullscreen-panel {
  max-width: 100vw !important;
  width: 100vw !important;
  height: 100vh;
}

.layout-master-view-engine-container {
  padding: 0 10px;
  box-sizing: border-box;
  flex-direction: column;
  display: flex;
  flex: 1;
  height: 100%;
}

mat-tab-group,
.mat-tab-body-wrapper {
  height: 100%;
}

.flex-column {
  display: flex !important;
  flex-direction: column;
}

.action-separation:last-child,
.action-separation:first-child,
.action-separation + .action-separation {
  display: none;
}
