@use '@angular/material' as mat;
@import '../../../../../../iot4bos-ui/src/lib/scss/variables';

@mixin iot-platform-ui-detail-popup-theme($theme) {
  $primary-color: mat.get-color-from-palette(map-get($theme, primary), default-contrast);

  .mve-detail-popup-container {
    margin: 0;
    font-size: 12px;
    color: $primary-color;
  }

  .mve-detail-popup-title {
    word-break: break-all;
    font-size: 18px;
    color: #4f79b7
  }

  .mve-detail-popup-section-title {
    color: #b0b0b0;
  }

  .mve-detail-popup-title-clickable {
    &:hover {
      cursor: pointer;
      text-decoration: underline;
    }
  }
}
