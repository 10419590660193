@use '@angular/material' as mat;

@mixin iot-platform-ui-filter-engine-theme($theme) {
  $primary-color: mat.get-color-from-palette(map-get($theme, primary), default-contrast);
  $warn: mat.get-color-from-palette(map-get($theme, warn), default);

  .filter-engine-row {
    color: $primary-color;
  }

  .filter-engine-applied {
    background-color: #ffffff;
  }

  .add-button-off {
    height: 30px;
    width: 30px;

    &.mat-mini-fab .mat-button-wrapper {
      padding: 0;
    }

    .mat-icon {
      font-size: 20px;
      height: 28px;
      width: 28px;
    }
  }

  .filter-engine-max-capacity {
    color: $warn;
    font-weight: 600;
  }
}
