@use '@angular/material' as mat;
@import '@angular/material/theming';
@import '@ag-grid-community/core/dist/styles/ag-grid.scss';

@mixin ag-grid-custom-theme($theme) {
  $ag-mat-accent: mat.get-color-from-palette(map-get($theme, accent), default);
  $primary-darker: mat.get-color-from-palette(map-get($theme, primary), darker);

  .ag-theme-material {
    font-size: 12px;
    font-family: Open Sans, sans-serif;

    .ag-header,
    .ag-header-viewport,
    .ag-header-row,
    .ag-header,
    .ag-layout-normal,
    .ag-row {
      background-color: #f8f8f8;
    }

    .ag-pinned-left-cols-container {
      &.ag-hidden + .ag-center-cols-clipper {
        .ag-row.ag-row-hover,
        .ag-row-selected {
          background-color: #fff;
          box-shadow: 0px 5px 10px rgb(0 0 0 / 10%);
          border-left: 6px solid #8ed0ff;

          .ag-cell {
            margin-left: -6px;
          }
        }

        .ag-row-selected {
          border-left-color: #4f78b7;
        }
      }

      &:not(.ag-hidden) {
        .ag-row.ag-row-hover,
        .ag-row-selected {
          background-color: #fff;
          box-shadow: 0px 5px 10px rgb(0 0 0 / 10%);
          border-left: 6px solid #8ed0ff;

          .ag-cell {
            margin-left: -6px;
          }
        }

        .ag-row-selected {
          border-left-color: #4f78b7;
        }

        & + .ag-center-cols-clipper {
          .ag-row.ag-row-hover,
          .ag-row-selected {
            background-color: #fff;
            box-shadow: 0px 5px 10px rgb(0 0 0 / 10%);
          }
        }
      }
    }

    .ag-pinned-right-cols-container {
      .ag-row.ag-row-hover,
      .ag-row-selected {
        background-color: #fff;
        box-shadow: 0px 5px 10px rgb(0 0 0 / 10%);
      }
    }

    .ag-ltr .ag-has-focus .ag-cell-focus:not(.ag-cell-range-selected) {
      border: none !important;
    }

    .ag-checkbox-input-wrapper.ag-checked::after {
      color: $ag-mat-accent;
    }

    .ag-cell {
      &.bg-red {
        background-color: #ffb5bc70;
      }
    }

    .ag-tooltip {
      background-color: $primary-darker;
      color: #ffffff;
      max-width: 300px;
    }
  }
}
