@use '@angular/material' as mat;
@import '../../../../../../scss/variables';

@mixin asset-variable-configure-parameters-theme($theme) {
  $primary: mat.get-color-from-palette(map-get($theme, primary), default);
  $primary-color: mat.get-color-from-palette(map-get($theme, primary), default-contrast);
  $warn: mat.get-color-from-palette(map-get($theme, warn), default);

  .asset-variable-parameter {
    &.valid-unit {
      background-color: $success-green;
    }

    &.invalid-unit {
      background-color: #f5d9dc;
    }

    &.untouched-form {
      background-color: $primary;
    }
  }

  .valid-unit-icon {
    color: $primary-color;
  }

  .invalid-unit-icon {
    color: $warn;
  }
}
