@use '@angular/material' as mat;
@mixin material-tooltip-theme($theme) {
  $primary-darker: mat.get-color-from-palette(map-get($theme, primary), darker);
  $warn: mat.get-color-from-palette(map-get($theme, warn), default);
  $warn-color: mat.get-color-from-palette(map-get($theme, warn), default-contrast);

  .mat-tooltip.regular-tooltip {
    background-color: $primary-darker;
    max-width: 300px;
  }

  .mat-tooltip.warning-tooltip {
    background-color: $warn;
    color: $warn-color;
  }
}
