@use '@angular/material' as mat;
@mixin material-select-theme($theme) {
  $primary-color: mat.get-color-from-palette(map-get($theme, primary), default-contrast);

  .mat-form-field-infix {
    mat-select {
      font-size: 12px;
    }

    label.mat-form-field-label {
      font-size: 14px;
    }
  }

  mat-select:not(.mat-select-disabled) {
    .mat-select-value {
      color: $primary-color;
    }
  }

  mat-option {
    &:not(.mat-option-disabled) .mat-option-text {
      color: $primary-color;
    }

    &.mat-selected:not(.mat-option-disabled) .mat-option-text {
      color: mat.get-color-from-palette(map-get($theme, accent), default);
    }
  }

  .mat-select-panel {
    .mat-optgroup-label {
      height: 28px;
      line-height: 28px;
      font-weight: 600;
    }

    //.mat-option {
    //  height: 22px;
    //  line-height: 22px;
    //}
  }

  .mat-autocomplete-panel {
    .mat-option {
      height: 3em;
      line-height: 3em;
    }

    //.mat-option {
    //  height: 22px;
    //  line-height: 22px;
    //}
  }
}
