@use '@angular/material' as mat;
@mixin material-tab-theme($theme) {
  $primary: mat.get-color-from-palette(map-get($theme, primary), default);
  $primary-color: mat.get-color-from-palette(map-get($theme, primary), default-contrast);
  $accent: mat.get-color-from-palette(map-get($theme, accent), default);

  .mat-tab-header {
    height: 31px;
    border-bottom: none;
  }

  .mat-tab-header .mat-tab-label {
    height: 31px;
    font-size: 12px;
    color: $primary-color;
    opacity: 0.6;

    &.mat-tab-label-active {
      opacity: 1;
      color: $accent;
      font-weight: 600;
    }
  }

  .mat-tab-labels {
    background-color: #ffffff;
  }
}
