@use '@angular/material' as mat;
@mixin event-detail-popup-theme($theme) {
  $primary: mat.get-color-from-palette(map-get($theme, primary), default);
  $primary-color: mat.get-color-from-palette(map-get($theme, primary), default-contrast);
  $accent: mat.get-color-from-palette(map-get($theme, accent), default);
  $accent-color: mat.get-color-from-palette(map-get($theme, accent), default-contrast);
  $warn: mat.get-color-from-palette(map-get($theme, warn), default);

  .event-detail-popup-tag-list .mat-chip-list-wrapper {
    width: 100%;
  }

  .event-detail-popup-tab-container {
    height: 280px;
    margin-top: 20px;
    overflow-y: auto;
  }

  .event-timeline-log {
    color: $primary-color;
    background-color: $primary;
  }

  .event-timeline-card {
    color: $primary-color;
    background-color: $primary;
  }

  .event-timeline-card-code {
    color: $primary-color;
    background-color: #f6f7fa;
  }
}

@mixin old-status($theme) {
  $accent: mat.get-color-from-palette(map-get($theme, accent), default);
  $accent-color: mat.get-color-from-palette(map-get($theme, accent), default-contrast);

  .event-timeline-container::after {
    border-color: $accent;
  }
  .event-timeline-name {
    color: $accent;
  }
  .event-timeline-icon {
    border-color: $accent;
    color: $accent;
    background-color: $accent-color;
  }
  .event-timeline-time {
    color: $accent;
  }
  .event-timeline-info {
    border-color: $accent;
  }
  .event-timeline-info::before {
    border-bottom-color: $accent;
  }
}

@mixin disabled-status {
  .event-timeline-name {
    color: #e3e3e3;
  }
  .event-timeline-icon {
    border-color: #e3e3e3;
    color: #fff;
    background-color: #e3e3e3;
  }
  .event-timeline-time {
    color: #e3e3e3;
  }
}

@mixin current-status($theme) {
  $accent: mat.get-color-from-palette(map-get($theme, accent), default);
  $accent-color: mat.get-color-from-palette(map-get($theme, accent), default-contrast);

  .event-timeline-name {
    color: $accent;
  }
  .event-timeline-icon {
    border-color: $accent;
    background-color: $accent;
  }
  .event-timeline-time {
    color: $accent;
  }
  .event-timeline-info {
    border-color: $accent;
  }
  .event-timeline-info::before {
    border-bottom-color: $accent;
  }
}

@mixin event-timeline-theme($theme) {
  .event-timeline.active {
    & .event-timeline-item:nth-child(1) {
      @include old-status($theme);
    }

    & .event-timeline-item:nth-child(2) {
      @include current-status($theme);
    }
  }

  .event-timeline.snoozed {
    & .event-timeline-item:nth-child(1),
    .event-timeline-item:nth-child(2) {
      @include old-status($theme);
    }

    & .event-timeline-item:nth-child(3) {
      @include current-status($theme);
    }
  }

  .event-timeline.acknowledged {
    & .event-timeline-item:nth-child(1),
    .event-timeline-item:nth-child(2),
    .event-timeline-item:nth-child(3) {
      @include old-status($theme);
    }

    & .event-timeline-item:nth-child(4) {
      @include current-status($theme);
    }
  }

  .event-timeline.acknowledgedWithoutSnoozed {
    & .event-timeline-item:nth-child(1),
    .event-timeline-item:nth-child(2),
    .event-timeline-item:nth-child(3) {
      @include old-status($theme);
    }

    & .event-timeline-item:nth-child(3) {
      @include disabled-status();
    }

    & .event-timeline-item:nth-child(4) {
      @include current-status($theme);
    }
  }

  .event-timeline.closed {
    & .event-timeline-item:nth-child(1),
    .event-timeline-item:nth-child(2),
    .event-timeline-item:nth-child(3),
    .event-timeline-item:nth-child(4) {
      @include old-status($theme);
    }

    & .event-timeline-item:nth-child(5) {
      @include current-status($theme);
    }
  }

  .event-timeline.closedWithoutSnoozed {
    & .event-timeline-item:nth-child(1),
    .event-timeline-item:nth-child(2),
    .event-timeline-item:nth-child(3),
    .event-timeline-item:nth-child(4) {
      @include old-status($theme);
    }

    & .event-timeline-item:nth-child(3) {
      @include disabled-status();
    }

    & .event-timeline-item:nth-child(5) {
      @include current-status($theme);
    }
  }

  .event-timeline.closedWithoutAcknowledged {
    & .event-timeline-item:nth-child(1),
    .event-timeline-item:nth-child(2),
    .event-timeline-item:nth-child(3),
    .event-timeline-item:nth-child(4) {
      @include old-status($theme);
    }

    & .event-timeline-item:nth-child(4) {
      @include disabled-status();
    }

    & .event-timeline-item:nth-child(5) {
      @include current-status($theme);
    }
  }

  .event-timeline.closedWithoutSnoozedAndAcknowledged {
    & .event-timeline-item:nth-child(1),
    .event-timeline-item:nth-child(2),
    .event-timeline-item:nth-child(3),
    .event-timeline-item:nth-child(4) {
      @include old-status($theme);
    }

    & .event-timeline-item:nth-child(3),
    .event-timeline-item:nth-child(4) {
      @include disabled-status();
    }

    & .event-timeline-item:nth-child(5) {
      @include current-status($theme);
    }
  }
}
