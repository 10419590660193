@use '@angular/material' as mat;
@import '../variables';
@import '../iot4bos-ui-components/iot4bos-ui-components-theme';

@mixin material-dialog-theme($theme) {
  .mat-dialog-container {
    padding: 0px !important;
    border-radius: $app-border-radius !important;

    .matButton {
      border-radius: $app-border-radius !important;
    }
  }

  mat-card.dialog-card {
    margin: 0px;
    padding: 0px;
  }

  mat-card-title.dialog-card-header {
    margin: 0px;
    padding: 0px;
  }

  .dialog-card-title {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  mat-toolbar.dialog-toolbar {
    display: flex;
    margin: 0px;
    place-content: center space-between;
    align-items: center;
    flex-direction: row;

    h1 {
      font-weight: normal;
      text-transform: uppercase;
    }
  }

  mat-card-content.dialog-card-content {
    margin: 0px;
    color: mat.get-color-from-palette(map-get($theme, primary), default-contrast) !important;
  }

  mat-card-actions.dialog-card-actions {
    display: flex;
    background-color: $light-grey;
    margin: 0px !important;
    padding: 10px !important;
    place-content: center flex-end;
    align-items: center;
    flex-direction: row;
  }

  .dialog-form-tips {
    font-size: 10px;
    line-height: 10px;
    font-style: italic;
    color: $medium-grey;
  }

  .cdk-global-overlay-wrapper {
    overflow: auto;
  }
}
