@import url('https://fonts.googleapis.com/css?family=Roboto:400,700|Material+Icons');

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
caption,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
button {
  outline: none;
}

html {
  font-family: 'Open Sans', sans-serif;
  background-color: #f8f8f8;
}

.app-round-button {
  border-radius: 50%;
  color: #b0b0b0;
}

.app-round-button:hover {
  background-color: rgba(57, 76, 90, 0.05);
}

.app-round-button button[aria-expanded='true'] {
  background-color: rgba(57, 76, 90, 0.05);
}

.app-round-button button.cdk-focused.cdk-program-focused {
  background-color: rgba(57, 76, 90, 0);
}

.app-round-button-mv {
  display: inline-block;
  border-radius: 50%;
}

.app-round-button-mv:hover {
  background-color: rgba(57, 76, 90, 0.05);
}

.app-round-button-mv button[aria-expanded='true'] {
  background-color: rgba(57, 76, 90, 0.05);
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-track {
  border-radius: 20px;
  background-color: #f8f8f8;
}

::-webkit-scrollbar-thumb {
  border-radius: 20px;
  background-color: #d0d0d0;
}

.fade-in {
  animation: fadeIn ease-in-out 0.2s;
  -webkit-animation: fadeIn ease-in-out 0.2s;
  -moz-animation: fadeIn ease-in-out 0.2s;
  -o-animation: fadeIn ease-in-out 0.2s;
  -ms-animation: fadeIn ease-in-out 0.2s;
}
