@mixin oyan-ui-global-overrides($theme) {

  .main-container__build-info iot-platform-ui-build-info {
    &:first-child .version {
      padding-bottom: 0;
    }

    .version:hover {
      cursor: auto;
      text-decoration: initial;
    }
  }

  .no-data-message {
    font-size: 12px;
    padding: 0.5rem;
    color: #b0b0b0;
    text-align: center;
    text-transform: uppercase;
    width: 100%;
  }
  .panel-grid > * {
    width: 99%;
  }

  .communications {
    .mat-paginator-navigation-last {
      display: none !important;
    }

    &-toolbar {
      .mat-form-field-infix {
        padding: 0;
      }
    }
  }

  .mat-menu-list {
    .mat-menu-content {
      margin: 8px 1rem;
    }

    .mat-menu-item {
      margin: 0;
      padding: 0;
    }
  }

  .link-business-profile {
    .mat-list-item {
      min-height: 50px;
      height: auto !important;
      h3,
      .complementary-line span {
        text-overflow: ellipsis;
        white-space: break-spaces;
        overflow: hidden;
      }
    }
  }

  .business-profile-select {
    .mat-select-value {
     max-width: 20rem !important;
    }
  }

  .iot-platform-toolbar {
    &__kerlink-button {
      border-radius: 20px;
      width: 137px;
      &:hover {
        box-shadow: inset 0px 0px 20px 3px rgb(0 0 0 / 20%), 0px 6px 10px 0px rgb(0 0 0 / 14%), 0px 1px 18px 0px rgb(0 0 0 / 12%);
      }
    }
  }
}
