@use '@angular/material' as mat;

@mixin iot-platform-ui-card-loader-theme($theme) {
  $primary-color: mat.get-color-from-palette(map-get($theme, primary), default-contrast);
  $primary: mat.get-color-from-palette(map-get($theme, primary), default);
  $accent: mat.get-color-from-palette(map-get($theme, accent), default);

  .detail-full-card-list {
    border: 10px solid $primary;
    color: $primary-color;
  }

  .mv-detail-card {
    color: $primary-color;
    background-color: $primary;
  }

  .mv-detail-no-bg {
    color: $primary-color;
  }

  .tag-loader {
    &.mv-detail {
      background-color: $primary;
    }
  }

  .admin-users-bp-associated {
    color: $primary-color;
  }

  .bar:nth-child(1) {
    background-color: $accent;
  }
}
