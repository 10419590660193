@import '@ag-grid-community/core/dist/styles/ag-grid.scss';
@import '@ag-grid-community/core/dist/styles/ag-theme-material/sass/ag-theme-material-mixin.scss';

$aggrid-selection-checkbox-size: 13px;

.ag-theme-material {
  @include ag-theme-material(
    (
      grid-size: 2.5px
    )
  );

  .ag-selection-checkbox,
  .ag-header-select-all {
    .ag-checkbox-input-wrapper {
      font-size: $aggrid-selection-checkbox-size;
      width: $aggrid-selection-checkbox-size;
      height: $aggrid-selection-checkbox-size;
    }
  }
  .ag-cell-wrapper {
    // width: inherit;
    height: 100%;
  }
}
