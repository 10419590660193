@use '@angular/material' as mat;
@import '../../../../scss/variables';

@mixin device-latest-command-status-theme($theme) {
  $primary: mat.get-color-from-palette(map-get($theme, primary), default);
  $primary-color: mat.get-color-from-palette(map-get($theme, primary), default-contrast);
  $accent: mat.get-color-from-palette(map-get($theme, accent), default);
  $accent-color: mat.get-color-from-palette(map-get($theme, accent), default-contrast);
  $warn: mat.get-color-from-palette(map-get($theme, warn), default);
  $warn-color: mat.get-color-from-palette(map-get($theme, warn), default-contrast);

  .dlcs-container {
    color: $primary-color;
  }

  .dlcs-step-ongoing {
    background-color: $accent;
    color: $accent-color;
  }

  .dlcs-step-validated {
    background-color: #e4f4d0;
    color: $primary-color;

    .mat-icon {
      color: $primary-color;
    }
  }

  .dlcs-step-error {
    background-color: #f5d9dc;
    color: $warn;

    .mat-icon {
      color: $warn;
    }
  }
}
