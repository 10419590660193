@use '@angular/material' as mat;
@mixin breadcrumb-item-theme($theme) {
  $primary: mat.get-color-from-palette(map-get($theme, primary), default);
  $primary-color: mat.get-color-from-palette(map-get($theme, primary), default-contrast);
  $accent: mat.get-color-from-palette(map-get($theme, accent), default);
  $accent-color: mat.get-color-from-palette(map-get($theme, accent), default-contrast);

  .breadcrumb-item {
    background-color: $accent;
    color: $accent-color;
  }

  .breadcrumb-item-icon {
    color: $accent-color;
  }

  .breadcrumb-item-close-button {
    &:hover {
      background-color: $accent-color;
      color: $accent;
    }
  }

  .passive {
    color: $accent;
  }

  .origin {
    background: #a5d564;
    color: white;
  }
}
