@use '@angular/material' as mat;

@mixin escalation-protocol-level-theme($theme) {
  $accent: mat.get-color-from-palette(map-get($theme, accent), default);
  $primary-color: mat.get-color-from-palette(map-get($theme, primary), default-contrast);
  $primary: mat.get-color-from-palette(map-get($theme, primary), default);

  .ep-level-container {
    background-color: #ffffff;

    &.ep-level-container-readonly {
      background-color: #f8f8f8;

      &:hover {
        border: none;
      }

      .ep-level-tp-calendar-area {
        background-color: #ffffff;
      }
    }

    &:hover {
      border-left: 4px solid #8ed0ff;
    }

    &.ep-level-container-move-only {
      border-left: 4px solid $accent;
    }
  }

  .ep-level-tp-calendar-area {
    background-color: #f8f8f8;
  }
}
