@use '@angular/material' as mat;
@import 'variables';

// Define a custom typography config that overrides the font-family
$custom-typography: mat.define-typography-config(
  $font-family: 'Open Sans, sans-serif'
);

// Override typography for all Angular Material, including mat-base-typography and all components.
@include mat.all-component-typographies($custom-typography);

// Override the typography in the core CSS.
@include mat.core($custom-typography);

.complementary-line {
  font-size: 12px !important;
  color: $medium-grey;
}

.fw-600 {
  font-weight: 600;
}

.capitalize-first-letter {
  &:first-letter {
    text-transform: capitalize;
  }
}
