////////////
// COLORS //
////////////

$light-grey: #f8f8f8;
$medium-grey: #c8c8c8;
$dark-grey: #394c5a;
$success-green: #e4f4d0;

////////////
// LAYOUT //
////////////

$regular-padding: 20px;
$app-border-radius: 0px;
$app-sidenav-width: 250px;
