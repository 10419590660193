@use '@angular/material' as mat;
@mixin event-comments-theme($theme) {
  $primary: mat.get-color-from-palette(map-get($theme, primary), default);
  $primary-color: mat.get-color-from-palette(map-get($theme, primary), default-contrast);
  $accent: mat.get-color-from-palette(map-get($theme, accent), default);
  $accent-color: mat.get-color-from-palette(map-get($theme, accent), default-contrast);
  $warn: mat.get-color-from-palette(map-get($theme, warn), default);

  .event-comment-name {
    color: $primary-color;
  }

  .event-comment-value {
    color: $primary-color;
    background-color: $primary;
  }

  .event-comment-date {
    color: #b0b0b0;
  }

  .event-comments {
    background-color: $accent-color;
  }

  .event-comments-header {
    color: $accent-color;
    background-color: $accent;
  }

  .event-comments-footer {
    background-color: $accent;
  }
}
