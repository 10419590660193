@use '@angular/material' as mat;

@mixin iot-platform-ui-manage-tag-form-theme($theme) {
  $primary: mat.get-color-from-palette(map-get($theme, primary), default);
  $primary-color: mat.get-color-from-palette(map-get($theme, primary), default-contrast);
  $accent: mat.get-color-from-palette(map-get($theme, accent), default);

  .selected-tags {
    background-color: $primary;
    color: $primary-color;
  }

  .tag-list-accordion .mat-expansion-panel-body {
    margin-left: 20px;
    padding: 0 16px;
    border-left: 1px solid $primary-color;
  }
}
