@use '@angular/material' as mat;
@mixin home-favorite-view-theme($theme) {
  $primary: mat.get-color-from-palette(map-get($theme, primary), default);
  $primary-color: mat.get-color-from-palette(map-get($theme, primary), default-contrast);
  $warn: mat.get-color-from-palette(map-get($theme, warn), default);

  .favorite-view-card {
    box-shadow: 0px 4px 8px #b0b0b0;

    .mat-icon {
      color: #b0b0b0;
    }
  }

  .favorite-view-text-info {
    background-color: #fff;
  }

  .favorite-view-name {
    color: $primary-color;
  }

  .favorite-view-mv {
    color: #b0b0b0;
  }

  .favorite-view-count {
    color: #fff;

    .mat-progress-spinner::ng-deep circle {
      stroke: #fff;
    }
  }
}
