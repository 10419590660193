@use '@angular/material' as mat;
@mixin tab-nav-bar-theme($theme) {
  $accent: mat.get-color-from-palette(map-get($theme, accent), default);

  .tab-nav-bar__list.mat-tab-nav-bar {
    position: sticky;
    left: 0;
    right: 0;
    top: 4rem;
    bottom: 0;
    z-index: 10;

    &.mat-tab-header {
      border-bottom: 1px solid #ADADAD;
      height: 40px;
      background: #f8f8f8;
    }

    &.mat-primary .mat-ink-bar {
      height: 3px;
    }
    &.centered .mat-tab-links {
      display: flex;
      place-content: center;
      align-items: center;
      flex-direction: row;
      box-sizing: border-box;
    }
  }
}
