@use '@angular/material' as mat;

@mixin iot-platform-ui-chip-theme($theme) {
  $accent: mat.get-color-from-palette(map-get($theme, accent), default);
  $accent-color: mat.get-color-from-palette(map-get($theme, accent), default-contrast);

  .mat-chip.mat-standard-chip {
    color: $accent-color;
    background-color: $accent;
  }
}
