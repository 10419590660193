@use '@angular/material' as mat;

@mixin master-view-table-theme($theme) {

  .table-container--sticky {
    &::-webkit-scrollbar {
      opacity:0;
      background: transparent;
      height: auto;
      width: 5px;
    }
    &::-webkit-scrollbar:hover {
      opacity: 1;
    }
    &::-webkit-scrollbar-thumb {
      background-color: transparent;
    }
    &:hover::-webkit-scrollbar-thumb {
      background-color: #d0d0d070;
    }
    .mat-table thead,
    mat-paginator {
      z-index: 1;
      position: sticky;
      background-color: #f8f8f8 !important;
    }
    .mat-table thead {
      top: 0px;
      th {
        background-color: #f8f8f8 !important;
      }
    }
    mat-paginator {
      bottom: 0px;
    }
  }
}
