@use '@angular/material' as mat;

@mixin iot-platform-ui-header-theme($theme) {
  $primary-color: mat.get-color-from-palette(map-get($theme, primary), default-contrast);

  button.navbar-business-profile-menu {
    &.mat-button[disabled][ng-reflect-disabled='true'] {
      color: $primary-color;
    }
  }

  header {
    color: $primary-color;
  }

  .border-right {
    &::after {
      border-right: 1px solid $primary-color;
    }
  }

  .border-left {
    &::before {
      border-left: 1px solid $primary-color;
    }
  }
}
